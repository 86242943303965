import Vue from 'vue'
import Router from 'vue-router'
import { auth } from '../plugins/firebase'
import appRoutes from './app'
import adminRoutes from "./admin"
import store from '../store'

const routerOptions = [
  ...appRoutes,
  ...adminRoutes,
  { path: '*', component: 'NotFound' }
]

const routes = routerOptions.map(route => {
  let imports = {}
  if (route.components) {
    imports = { components: {} }
    Object.keys(route.components).map(k => {
      imports.components[k] = () => import(`@/views/${route.components[k]}.vue`)
    })
  } else if (route.component) {
    imports = { component: () => import(`@/views/${route.component}.vue`) }
  }
  return {
    ...route,
    ...imports
  }
})

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/waitForUserData')
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  const isAuthenticated = auth.currentUser ? true : false
  const isAdmin = store.getters['auth/isAdmin']
  if (requiresAdmin && !isAdmin) {
    return next({ name: "authLogin" })
  } else if (requiresAuth && !isAuthenticated) {
    return next({ name: "authLogin" })
  }
  next()
})

export default router