import Vue from 'vue'

const Snackbar = {
  install(Vue) {
    Vue.prototype.$snackbar = function ({ content = "", color = "" }) {
      this.$store.commit("snackbar/showMessage", { content, color })
    }
  }
}

Vue.use(Snackbar)
export default {}