import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/es5/util/colors'
import VuetifyConfirm from "vuetify-confirm";


Vue.use(Vuetify);
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        base: colors.blueGrey,
        primary: "#8c2242",
        secondary: colors.grey,
        accent: colors.green.darken2,
        error: colors.red,
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }, dark: {
        primary: colors.green,
      },
    },
    dark: false
  },
})

Vue.use(VuetifyConfirm, { vuetify })

export default vuetify