function getTitle(vm) {
  const { title } = vm.$options
  if (title) {
    return typeof title === 'function'
      ? title.call(vm)
      : title
  }
}

export default {
  created() {
    const title = getTitle(this)
    if (!this?.$i18n) return process.VUE_APP_TITLE
    if (title) {
      document.title = this.$t('title') + " - " + title
    } else {
      document.title = this.$t('title')
    }
  }
}