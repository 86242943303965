import firebaseSettings from './firebaseConfig.js'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/storage'

firebase.initializeApp(firebaseSettings)

const fb = firebase.database()
const auth = firebase.auth()
const storage = firebase.storage()
const fn = firebase.app().functions('europe-west1')

// firebase.database().functions().httpsCallable()

if (process.env.VUE_APP_FIREBASE_USE_EMUALTOR) {
  auth.useEmulator("http://localhost:9099/", { disableWarnings: true })
  fb.useEmulator("localhost", 9000)
  fn.useEmulator("localhost", 5001)
  
}
const storagePrefix = process.env.VUE_APP_FIREBASE_STORAGE_PREFIX || "shaker"

export { fb, auth, storage, fn, storagePrefix }