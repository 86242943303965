// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { auth } from './plugins/firebase'
import { } from "./plugins/snackbar"
import titleMixin from './mixins/title'
import eventHub from "./mixins/eventhub"
import i18n from './i18n'
import "./styles/app.stylus"
import './registerServiceWorker'

Vue.mixin(titleMixin)
Vue.mixin(eventHub)
Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
});


auth.onAuthStateChanged(async (user) => {
  let { uid, email, displayName } = user ?? {}
  let claims = {}
  if (user) {
    let tokenResult = await auth.currentUser.getIdTokenResult()
    claims = tokenResult.claims
  }
  await store.dispatch('auth/autoLogin', { uid, email, displayName, claims })
  app.$mount("#app")
})
