<template lang="pug">
v-app
  v-app-bar(
    app,
    fixed,
    clipped-right,
    clipped-left,
    color="primary",
    dark,
    :height="120",
    @click="goWelcome"
  ) 
    .text-h1.ml-auto.mr-auto {{ $t('title') }}
  v-main
    router-view
  v-bottom-navigation(
    v-if="isAuthenticated",
    app,
    fixed,
    shift,
    dark,
    background-color="primary",
    v-model="section"
  )
    v-btn(
      v-for="n in navigation",
      :value="n.value",
      :key="n.value",
      @click="go(n.to)"
    )
      span {{ n.text }}
      v-icon {{ n.icon }}
  snackbar
</template>

<script>
import { mapGetters } from "vuex";
import Snackbar from "@/components/Snackbar";
export default {
  components: { Snackbar },
  data() {
    return {
      sidebar: false,
      section: "play",
      navigation: [
        {
          icon: "mdi-play-circle",
          text: this.$t("sections.play"),
          value: "game",
          to: { name: "gameTimeline" },
        },
        {
          icon: "mdi-thermometer-lines",
          text: this.$t("sections.result"),
          value: "result",
          to: { name: "gameResult" },
        },
        {
          icon: "mdi-account",
          text: this.$t("sections.account"),
          value: "account",
          to: { name: "authAccount" },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      userName: "auth/displayName",
    }),
    appTitle() {
      return process.env.VUE_APP_TITLE;
    },
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
  },
  mounted() {
    this.section = this.$route.meta.section || 'game';
  },
  watch: {
    "$route.meta.section": function (val) {
      this.section = val || 'game';
    },
  },
  methods: {
    goWelcome() {
      if (this.$route.name != "home") {
        this.$router.push({ name: "home" });
      }
    },
    go(to) {
      this.$router.push(to).catch(() => {});
    },
  },
};
</script>