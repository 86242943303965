<template lang="pug">
v-snackbar(v-model="show", app, bottom, :color="color")
  | {{ message }}
  template(v-slot:action="{ attrs }")
    v-btn(icon, @click="show = false") 
      v-icon mdi-close
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: "",
      color: "primary",
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/showMessage") {
        this.message = state.snackbar.content;
        this.color = state.snackbar.color;
        this.show = true;
      }
    });
  },
};
</script>