import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import snackbar from "./modules/snackbar"
import { vuexfireMutations } from 'vuexfire'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)
// See https://alligator.io/vuejs/vuex-persist-state/ for reference
const vuexLocalStorage = new VuexPersistence({
  key: 'shaker', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: (/*state*/) => ({
    // registration: {
    //   current: state.registration.current
    // }
  }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})


export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  modules: {
    auth, snackbar
  },
  state: {},
  mutations: {
    ...vuexfireMutations
  },
  actions: {},
  getters: {}
})
