const appRoutes = [
  { path: '/', name: 'home', components: { default: 'Welcome' }, meta: { requiresAuth: false, section: 'play' } },
  { path: '/login', name: 'authLogin', components: { default: 'Login' }, meta: { requiresAuth: false, section: 'account' } },
  { path: '/logout', name: 'authLogout', components: { default: 'Logout' }, meta: { requiresAuth: false, section: 'account' } },
  { path: '/register', name: 'authRegister', components: { default: 'Register' }, meta: { requiresAuth: false, section: 'account' } },
  { path: '/password', name: 'authPasswordReset', components: { default: 'PasswordReset' }, meta: { requiresAuth: false, section: 'account' } },
  { path: '/account', name: 'authAccount', components: { default: 'Account' }, meta: { requiresAuth: true, section: 'account' } },
  { path: '/timeline', name: 'gameTimeline', components: { default: 'Timeline' }, meta: { requiresAuth: true, section: 'game' } },
  { path: '/shuffle', name: 'gameShuffle', components: { default: 'Shuffle' }, meta: { requiresAuth: true, section: 'game' } },
  { path: '/result', name: 'gameResult', components: { default: 'Result' }, meta: { requiresAuth: true, section: 'game' } }
]

export default [...appRoutes]