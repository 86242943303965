import { auth, fb } from '@/plugins/firebase'
import { firebaseAction } from 'vuexfire'

const state = {
  user: null, // uid and e-mail, display name
  loaded: false,
  claims: {},
  account: {}
}

const mutations = {
  setUser: (state, payload) => state.user = payload,
  setClaims: (state, { claims }) => state.claims = { ...claims },
  setLoaded: (state, payload) => state.loaded = payload
}
const getters = {
  isAuthenticated: (state) => state.user !== null && state.user !== undefined,
  isAdmin: (state) => Boolean(state.claims?.admin),
  currentUid: (state) => state.user.uid,
  displayName: (state) => state.user?.displayName,
  account: (state) => state.account,
  isLoaded: (state) => state.loaded
}

const actions = {
  bindAccount: firebaseAction(({ bindFirebaseRef, state }) => {
    let uid = state.user.uid
    return bindFirebaseRef('account', fb.ref(`u/${uid}/account`))
  }),
  async userLogin({ commit, dispatch }, { email, password }) {
    let firebaseUser = await auth.signInWithEmailAndPassword(email, password)
    let { uid, displayName } = firebaseUser?.user
    commit('setUser', { uid, email, displayName })
    await dispatch('bindAccount')
  },
  async autoLogin({ commit, dispatch }, { uid, email, claims, displayName }) {
    if (uid && email) {
      commit('setUser', { uid, email, displayName })
      await dispatch('bindAccount')
      if (claims) commit('setClaims', { claims })
    }
    commit('setLoaded', true)
  },
  async waitForUserData({ state }) {
    if (state.loaded) return true
    const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
    while (state.loaded == false) {
      await wait(100)
    }
    return true
  },
  async userLogout({ commit }) {
    commit('setUser', null)
    await auth.signOut()
  },
  async registerEmail({ commit }, { email, password, account }) {
    let firebaseUser = await auth.createUserWithEmailAndPassword(email, password)
    let { uid, displayName } = firebaseUser?.user
    await fb.ref(`u/${uid}/account`).set(account)
    commit('setUser', { uid, email, displayName })
  },
  async updateAccount({ state }) {
    let uid = state.user.uid
    await fb.ref(`u/${uid}/account`).set(state.account)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
